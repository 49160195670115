import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import { newIsoChrone } from '@propertypal/shared/src/reducers/map';
import { useAppDispatch } from '@propertypal/shared/src/reducers/store';
import Pencil from '@propertypal/shared/src/resources/icons/cursor/pencil.png';
import axios, { API_URL } from '@propertypal/shared/src/services/axios';
import { MapBounds } from '@propertypal/shared/src/types/map';
import wrapApiPolygon from '@propertypal/shared/src/utils/polygons';
import Button from '@propertypal/web-ui/src/buttons/Button';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import DrawCanvas, { PathPoint } from '@propertypal/web-ui/src/map/DrawCanvas';
import useMediaQuery from '@propertypal/web-ui/src/media-query/useMediaQuery';
import ModalWrapper from '@propertypal/web-ui/src/modal/ModalWrapper';
import TravelSearchBox from '@propertypal/web-ui/src/search/TravelSearchBox';
import { SubHeading } from '@propertypal/web-ui/src/typography';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'react-spinners/PulseLoader';
import { useTheme } from 'styled-components';
import GoogleMap, { centerToPolygon, DEFAULT_ZOOM, getBounds, getLatLngFromPoint, getZoom } from '../map/GoogleMap';
import MapControls from '../map/MapControls';
import { Container, Content, Footer, Header, MapContainer, TravelSearchWrapper } from './SearchPreviewModal.style';

interface Props {
  show: string;
  onClose: () => void;
  onSubmit: (polyIds: number[], category: string, saleType: string) => void;
  showSaleTypeOptions?: boolean;
  initialPolyIds?: number[];
}

const TITLE: { [key: string]: string } = {
  travelTime: 'Enter location, choose travel mode and maximum travel time',
  drawSearch: 'Find properties in your ideal location by drawing your very own search area.',
};

const getPolygon = async (polyIds: number[]) => {
  try {
    const polyIdsParam = polyIds.map((polyId) => `polyId=${polyId}`).join('&');

    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/search-map/properties?${polyIdsParam}`,
        params: {
          format: 'geoJson',
          q: '',
          zoom: DEFAULT_ZOOM,
        },
      },
      undefined,
    );

    return result.data;
  } catch {
    return null;
  }
};

const createPolygon = async (polyIds: number[], bounds: MapBounds, zoom: number, line: number[], subtract: boolean) => {
  try {
    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/search-map/polygons?format=geoJson`,
        data: {
          params: { category: ['residential'], saleType: ['sale'] },
          polyIds,
          northEast: {
            lat: bounds.north,
            lng: bounds.east,
          },
          southWest: {
            lat: bounds.south,
            lng: bounds.west,
          },
          zoom,
          line,
          subtract,
        },
      },
      undefined,
    );

    return result.data;
  } catch (err) {
    return null;
  }
};

const SearchPreviewModal: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const asyncDispatch = useAppDispatch();
  const [drawingActive, setDrawingActive] = useState(false);
  const [eraseActive, setEraseActive] = useState(false);
  const [polyIds, setPolyIds] = useState<number[]>([]);
  const [polygons, setPolygons] = useState<object[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isMobile = useMediaQuery({ query: `(${mobileXXLargeMax})` });

  const handleSearch = (category: string, saleType: string) => () => {
    props.onSubmit(polyIds, category, saleType);
  };

  const handleTravelTime = async (location: string, time: string, mode: string) => {
    setLoading(true);
    setError('');

    try {
      const data = await asyncDispatch(newIsoChrone(location, time, mode));

      setPolyIds(data.polygonIds);
      setPolygons([wrapApiPolygon(data.coordinates)]);
      centerToPolygon(data.coordinates, {
        top: isMobile ? 200 : 100,
        left: 10,
        right: 10,
        bottom: 10,
      });
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setError('Invalid location');
      } else {
        setError('Internal server error. Please try again later.');
      }
    }

    setLoading(false);
  };

  const handleClearPolygons = () => {
    setPolygons([]);
    setPolyIds([]);
  };

  const handleDrawEnd = async (path: PathPoint[]) => {
    const bounds = getBounds();
    const zoom = getZoom();
    const line: number[] = [];

    path.forEach((point) => {
      const latLng = getLatLngFromPoint(point.x, point.y);

      if (latLng) {
        line.push(latLng.lat);
        line.push(latLng.lng);
      }
    });

    if (bounds && zoom) {
      const data = await createPolygon(polyIds, bounds, zoom, line, eraseActive);

      if (data !== null) {
        setPolyIds(data.polygonIds);
        setPolygons([wrapApiPolygon(data.coordinates)]);
      }
    }

    setEraseActive(false);
    setDrawingActive(false);
  };

  const loadInitialPolygon = async () => {
    if (props.initialPolyIds && props.show === 'drawSearch') {
      const data = await getPolygon(props.initialPolyIds);

      if (data) {
        setPolyIds(props.initialPolyIds);
        setPolygons([wrapApiPolygon(data.polygons.coordinates)]);
      }
    }
  };

  useEffect(() => {
    loadInitialPolygon();
  }, []);

  return (
    <ModalWrapper show={!!props.show} onClose={props.onClose} scrollSelector="#searchPreviewModal">
      <Container id="searchPreviewModal">
        <Content>
          <Header>
            <SubHeading fontSize={20} mb={5} mr={15}>
              {TITLE[props.show]}
            </SubHeading>

            <button type="button" onClick={props.onClose}>
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: 30 }} color={theme.textDark} />
            </button>
          </Header>

          <MapContainer>
            <GoogleMap width="100%" height="100%" polygons={polygons} hideStreetView gestureHandling="greedy">
              {props.show === 'travelTime' && (
                <TravelSearchWrapper>
                  <TravelSearchBox onUpdate={handleTravelTime} error={error} />
                </TravelSearchWrapper>
              )}

              {props.show === 'drawSearch' && (
                <MapControls
                  containerStyle={{ top: 0 }}
                  onDrawClick={() => setDrawingActive(true)}
                  onEraseClick={() => setEraseActive(true)}
                  onTrashClick={handleClearPolygons}
                />
              )}

              {(drawingActive || eraseActive) && (
                <DrawCanvas
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    cursor: drawingActive ? `url("${Pencil.src}") 0 64, auto` : 'auto',
                  }}
                  strokeWidth={8}
                  strokeColor={eraseActive ? theme.darkGrey : theme.primary}
                  onStroke={handleDrawEnd}
                />
              )}
            </GoogleMap>
          </MapContainer>

          <Footer>
            {!loading && props.showSaleTypeOptions && (
              <>
                <Button onClick={handleSearch('residential', 'sale')}>For Sale</Button>
                <Button onClick={handleSearch('residential', 'rent')}>For Rent</Button>
                <Button onClick={handleSearch('newhomes', 'sale')}>New Homes</Button>
              </>
            )}

            {!loading && !props.showSaleTypeOptions && <Button onClick={handleSearch('', '')}>Search</Button>}

            {loading && <Loader color={theme.backgroundMid} />}
          </Footer>
        </Content>
      </Container>
    </ModalWrapper>
  );
};

export default SearchPreviewModal;
